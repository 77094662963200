import React, { useEffect, useState } from 'react'
import httpClient from "../httpClient"
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { message, Upload } from 'antd'
import './ImageUploader.css'

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 5
  if (!isLt2M) {
    message.error('Image must smaller than 5MB!')
  }
  return isJpgOrPng && isLt2M
}

const ImageUploader = ({ state, setState, id, resetState, imageUrl, setImageUrl, type }) => {
  const [loading, setLoading] = useState(false)

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, url => {
        setLoading(false)
        setImageUrl(url)
      })
    }
  }

  const uploadImage = async ({ file, onSuccess, onError }) => {
    const formData = new FormData()
    formData.append('image', file)

    try {
      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/image-upload?type=${type}`, formData)
      onSuccess(response.data.url)

      const prevState = { ...state }
      prevState['direct_link'] = response.data.url
      setState(prevState)

    } catch (error) {
      message.error('upload failed')
      onError(error)
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload Image</div>
    </div>
  )

  return (
    <div className='image_uploader__container' id={id}>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={uploadImage}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    </div>
  )
}

export default ImageUploader

ImageUploader.defaultProps = {
  type: null
}
