import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux"
import './DatePicker.css'
import "../styling/flatpickr_dark.css"
import Flatpickr from 'react-flatpickr'
import { Dutch } from "flatpickr/dist/l10n/nl.js"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ClearIcon from '@mui/icons-material/Clear'

function DatePicker({ setDate, date, placeholder, enableTime, altInput, style }) {
  const [dateTimeFormat, setDateTimeFormat] = useState('d-m-Y H:i')
  const [defaultDate, setDefaultDate] = useState(null)
  const [options, setOptions] = useState({
    mode: 'single',
    dateFormat: dateTimeFormat,
    enableTime: enableTime,
    time_24hr: true,
    minTime: "00:00", // Default minTime
    maxTime: "23:59", // Default maxTime
    defaultDate: defaultDate,
    disableMobile: false,
  });

  useEffect(() => {
    if (enableTime) {
      setDateTimeFormat('d-m-Y H:i')
    }
  }, [enableTime])

  useEffect(() => {
    const defaultDate = new Date()
    setDefaultDate(defaultDate.setUTCHours(0, 0, 0, 0))  // Set time to 00:00:00.000 UTC
  }, [])

  useEffect(() => {
    if (style !== "mobile") {
      setOptions(prevOptions => ({
        ...prevOptions,
        weekNumbers: true,
        locale: Dutch,
      }))
    }
  }, [style])

  const dispatch = useDispatch()

  const clearDate = () => {
    setDate(null)
  }

  const handleSetDate = (selectedDate) => {
    console.log("datePicker:", selectedDate);
    if (selectedDate instanceof Date) {
      setDate(selectedDate.toISOString()); // Save as ISO string in UTC
    } else {
      const parsedDate = Date.parse(selectedDate);
      if (!isNaN(parsedDate)) {
        setDate(new Date(parsedDate).toISOString()); // Save as ISO string in UTC
      }
    }
  }

  return (
    <div className={`flatpickr_wrapper--date_picker ${style}`}>
      {
        (date == null && style == "mobile") ?
        <p>{placeholder}</p> : null
      }
      <Flatpickr
        className={`date_picker_input`}
        value={date ? new Date(date) : null}
        onChange={date => handleSetDate(date[0])}
        options={options}
      />
      {/* {
        date == null ?
          <CalendarMonthIcon className='icon' /> :
          <ClearIcon className='icon' onClick={clearDate} />
      } */}
    </div>
  )
}

export default DatePicker

DatePicker.defaultProps = {
  placeholder: "Select date",
  altInput: false,
  enableTime: false,
}
