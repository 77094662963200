import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: 'alert',
  initialState: {
    isActive: false,
    type: 'notification',
    message: '',
  },
  reducers: {
    showAlertPopup: (state, action) => {
      state.isActive = true
      state.type = action.payload.type
      state.message = action.payload.message
    },
    hideAlertPopup: (state) => {
      state.isActive = false
    },
  },
})

export const { showAlertPopup, hideAlertPopup } = notificationSlice.actions
export default notificationSlice.reducer