import { createAsyncThunk } from '@reduxjs/toolkit'
import httpClient from '../httpClient'

export const fetchMyTeam = createAsyncThunk(
  'user/fetchMyTeam',
  async (_, { getState, rejectWithValue }) => {
    const { user } = getState()
    
    // Check if user is logged in and of the correct type
    if (!user.userInfo.id || !["manager"].includes(user.userInfo.type)) {
      return rejectWithValue("User not authorized to fetch my team")
    }

    // Check if myTeam is already fetched
    if (user.myTeam !== null) {
      return user.myTeam // Return existing data if already fetched
    }

    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/brandowner/myteam?type=full`)
      const myTeamNamesArray = response.data
        .filter(obj => obj.active === true)
        .map(obj => ({ "id": obj.salesforce_id, "name": obj.salesforce_name }))

      return myTeamNamesArray
    } catch (error) {
      console.error('Error fetching myTeam:', error)
      return rejectWithValue(error.message || 'Failed to fetch my team')
    }
  }
)

export const fetchSuppliers = createAsyncThunk(
  'user/fetchSuppliers',
  async (_, { getState, rejectWithValue }) => {
    const { user } = getState()
    
    // Check if user is logged in and of the correct type
    if (!user.userInfo.id || !["wholesaler"].includes(user.userInfo.app_version)) {
      return rejectWithValue("User not authorized to fetch suppliers")
    } 

    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/suppliers/get")
      return response.data
    } catch (error) {
      console.error('Error fetching suppliers:', error)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchReportItems = createAsyncThunk(
  'user/fetchReportItems',
  async (_, { getState, rejectWithValue }) => {
    const { user } = getState()
    
    // Check if user is logged in and of the correct type
    if (!user.userInfo.id) {
      return rejectWithValue("User not authorized to fetch report items")
    }

    try {
      const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/user/classification/custom-user-details/get?type=report_items")
      return response.data
    } catch (error) { 
      console.error('Error fetching report items:', error)
      return rejectWithValue(error.message)
    }
  }
)
