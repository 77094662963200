import React, { useState } from 'react'
import './CustomerContentGrid.css'
import httpClient from '../httpClient'
import { CircularProgress } from '@mui/material'
import { PiPlusThin, PiTrashThin, PiCameraThin, PiUploadSimpleThin, PiXThin } from "react-icons/pi"
import { showAlertPopupMobile } from './helpers/NotificationHelpers'
import { MdDelete } from "react-icons/md"

function CustomerContentGrid({ customerID, content, fetchCustomerContent, isMobile, imageOnly, width }) {
  const [isUploading, setIsUploading] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const handleCapture = async (event) => {
    const files = event.target.files
    if (!files.length) return

    setIsUploading(true)

    try {
      for (let file of files) {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('customerID', customerID)
        formData.append('type', imageOnly ? 'photo' : 'file')

        await httpClient.post(`${process.env.REACT_APP_API_URL}/api/customer/content/upload`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
      }
      fetchCustomerContent()
    } catch (error) {
      showAlertPopupMobile({
        type: "error",
        message: "Failed to upload files"
      })
      console.error('Error uploading files:', error)
    } finally {
      setIsUploading(false)
    }
  }

  const handleDelete = async (contentID) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/customer/content/delete?contentID=${contentID}`
      const response = await httpClient.delete(url)
      fetchCustomerContent()
    } catch (error) {
      let errorMessage = "Failed to delete image"
      if (error.code === "ERR_NETWORK") {
        errorMessage = "Network error. Please check your internet connection and try again."
      }
      showAlertPopupMobile({
        type: "error",
        message: errorMessage
      })
      console.error('Error deleting content:', error)
    }
  }

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl)
  }

  const handleClosePopup = () => {
    setSelectedImage(null)
  }

  return (
    <div className={`customer-content-grid ${isMobile ? "mobile" : ""} ${!imageOnly ? "list-view" : ""}`} style={{ width: width }}>
      {
        content
          .filter((item) => !item.is_archived)
          .map((item) => {
            const fileName = item.content_url.split('/').pop();
            
            return imageOnly ? (
              <div key={item.id} className="content-item">
                <img
                  src={item.content_url}
                  alt="Customer content"
                  onClick={() => handleImageClick(item.content_url)}
                />
                <button className="delete-button" onClick={() => handleDelete(item.id)} disabled={isUploading}>
                  <MdDelete />
                </button>
              </div>
            ) : (
              <div key={item.id} className="content-item-list">
                <a 
                  href={item.content_url}
                  download={fileName}
                  className="file-name"
                >
                  {fileName}
                </a>
                <div className="list-actions">
                  <button className="delete-button" onClick={() => handleDelete(item.id)} disabled={isUploading}>
                    <MdDelete />
                  </button>
                </div>
              </div>
            )
          })
      }
      <div className={`content-item add-content`}>
        {
          imageOnly && (
            <>
              <input
                type="file"
                accept={imageOnly ? 'image/*' : '*/*'}
                multiple
                onChange={handleCapture}
                style={{ display: 'none' }}
                id="upload-input"
              />
              <label htmlFor="upload-input" className="option-button">
                <PiUploadSimpleThin /> Upload
              </label>
              {/* {
                isMobile && imageOnly &&
                <>
                  <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    onChange={handleCapture}
                    style={{ display: 'none' }}
                    id="capture-input"
                  />
                  <label htmlFor="capture-input" className="option-button">
                    <PiCameraThin /> Camera
                  </label>
                </>
              } */}
            </>
          )
        }
        {
          !imageOnly && (
            <>
              <input
                type="file"
                accept="*/*"
                multiple
                onChange={handleCapture}
                style={{ display: 'none' }}
                id="upload-input-files"
              />
              <label htmlFor="upload-input-files" className="option-button">
                {isUploading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>
                    <PiUploadSimpleThin /> Upload
                  </>
                )}
              </label>
            </>
          )
        }
      </div>
      {
        selectedImage && (
          <div className="fullscreen-popup" onClick={handleClosePopup}>
            <img src={selectedImage} alt="Full-size content" />
            <button className="close-button" onClick={handleClosePopup}>
              <PiXThin />
            </button>
          </div>
        )
      }
    </div>
  )
}

export default CustomerContentGrid

CustomerContentGrid.defaultProps = {
  isMobile: true,
  imageOnly: true,
  width: "100%"
}
