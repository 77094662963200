import React, { useState } from 'react'
import './ActivitiesCalendar.css'
import WeekCalendar from './WeekCalendar'

function ActivitiesCalendar({ activities, loading, setCreateActivity, setSelectedActivity, setEditActivity }) {
  const [calendarType, setCalendarType] = useState("week")

  return (
    <div className="activities-calendar">
      <div className="calendar">
        {
          calendarType === "week" &&
          <WeekCalendar
            activities={activities}
            loading={loading}
            setCreateActivity={setCreateActivity}
            setSelectedActivity={setSelectedActivity}
            setEditActivity={setEditActivity}
          />
        }
      </div>
    </div>
  )
}

export default ActivitiesCalendar
