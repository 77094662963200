import React, { useState, useEffect, useRef } from 'react'
import "./PopupDropdownMobile.css"
import { MdOutlineArrowDropDown, MdClear } from 'react-icons/md'

function PopupDropdownMobile({ id, value, placeholder, disabled, list, setValue, stateData, searchBar, darkTheme, stateType, setChangesMade }) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedType, setSelectedType] = useState('')
  const [searchValue, setSearchValue] = useState("")
  
  const dropdownListRef = useRef(null)
  const selectedViewBoxRef = useRef(null)

  const checkSelectedItem = () => {
    const dropdown = dropdownListRef.current
    const selectedView = selectedViewBoxRef.current
    if (!dropdown || !selectedView) return

    const scrollOffset = dropdown.scrollTop
    const selectedViewMiddle = selectedView.offsetTop + selectedView.offsetHeight / 2

    const items = dropdown.children
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const itemMiddle = item.offsetTop - scrollOffset + item.offsetHeight / 2

      if (itemMiddle >= selectedView.offsetTop && itemMiddle <= selectedView.offsetTop + selectedView.offsetHeight) {
        if (typeof list[0] === 'string' || typeof list[0] === 'number') {
          setSelectedType(item.textContent)
        }
        if (typeof list[0] === 'object') {
          setSelectedType(item.textContent)
        }
        break
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      setSelectedType('')
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && list.length > 0) {
      if (typeof list[0] === 'string' || typeof list[0] === 'number') {
        setSelectedType(list[0])
      }
      if (typeof list[0] === 'object') {
        setSelectedType(list[0].name)
      }
    }
  }, [isOpen, list])

  useEffect(() => {
    const dropdown = dropdownListRef.current
    if (dropdown) {
      dropdown.addEventListener('scroll', checkSelectedItem)
      return () => {
        dropdown.removeEventListener('scroll', checkSelectedItem)
      }
    }
  }, [list, isOpen])

  useEffect(() => {
    if (isOpen && searchValue) {
      const filteredList = list.filter(item =>
        typeof item === 'object' ?
          item.name.toLowerCase().includes(searchValue.toLowerCase()) :
          item.toLowerCase().includes(searchValue.toLowerCase())
      )

      if (filteredList.length > 0) {
        const firstItem = filteredList[0]
        const firstItemIndex = list.findIndex(item => item === firstItem || item.name === firstItem.name)
        if (firstItemIndex >= 0) {
          const itemHeight = 50 // Adjust based on your item height
          const selectedView = selectedViewBoxRef.current
          const selectedViewHeight = selectedView ? selectedView.offsetHeight : 0
          const offsetTop = selectedView ? selectedView.offsetTop : 0
          const scrollPosition = (firstItemIndex * itemHeight) - offsetTop + (selectedViewHeight / 2) - (itemHeight / 2)
          dropdownListRef.current.scrollTo(0, scrollPosition)
          setSelectedType(firstItem.name || firstItem)
        }
      }
    }
  }, [searchValue, isOpen, list])

  const handleClear = (e) => {
    e.stopPropagation()

    if (stateType === "useStateObject") {
      setValue(prev => ({ ...prev, [id]: "" }))
    } else if (stateType === "controlled") {
      setValue(e, id, "")
    } else {
      setValue("")
    }

    setIsOpen(false)
    setSearchValue("")
    setSelectedType("")
  }

  const handleOnclick = (e, value) => {
    e.stopPropagation()

    setChangesMade && setChangesMade(true)

    if (stateType === "useStateObject") {
      setValue(prev => ({ ...prev, [id]: value }))
    } else if (stateType === "controlled") {
      setValue(e, id, value)
    } else {
      setValue(value)
    }

    setIsOpen(false)
    setSearchValue("")
  }

  return (
    <div className={`popup_dropdown--mobile ${disabled ? "disabled" : ""}`} onClick={() => !disabled && setIsOpen(!isOpen)} key={id}>
      <div className="dropdown_display">
        {
          value ?
            <p>{value}</p> :
            <p className='placeholder'>{placeholder}</p>
        }
        <MdOutlineArrowDropDown />
      </div>

      {
        isOpen && (
          <div className={`popup_dropdown__container ${isOpen ? 'open' : ''} ${darkTheme ? "dark-theme" : ""}`} onClick={(e) => e.stopPropagation()}>
            <div className='clear_icon' onClick={handleClear}><p>Clear</p><MdClear /></div>
            {
              searchBar &&
              <input
                className="search_bar__dropdown--mobile"
                placeholder="Search..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.currentTarget.value.trim())}
              />
            }
            <div ref={selectedViewBoxRef} className='selected_view'></div>
            <div ref={dropdownListRef} className='popup_dropdown__list no-scrollbar'>
              {
                list
                  .filter(item => (
                    typeof item === 'object' ?
                      item.name.toLowerCase().includes(searchValue.toLowerCase()) :
                      item.toLowerCase().includes(searchValue.toLowerCase()))
                  )
                  .map((item, index) => {
                    const itemValue = typeof item === 'object' ? item.name : item
                    return (
                      <div
                        key={index}
                        className={`popup_dropdown__item ${selectedType === itemValue ? 'selected' : ''}`}
                        onClick={(e) => handleOnclick(e, item)}
                      >
                        {itemValue}
                      </div>
                    )
                  })
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

export default PopupDropdownMobile

PopupDropdownMobile.defaultProps = {
  stateTYpe: "normal",
  searchBar: false,
  darkTheme: false,
  disabled: false,
}