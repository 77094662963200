import { createSlice } from "@reduxjs/toolkit"
import { fetchSuppliers, fetchReportItems, fetchMyTeam } from "./userThunks"

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: {},
    customer_types: null,
    suppliers: [],
    suppliersStatus: 'idle',
    suppliersError: null,
    reportItems: [],
    reportItemsStatus: 'idle',
    reportItemsError: null,
    myTeam: null,
    myTeamStatus: 'idle',
    myTeamError: null,
  },
  reducers: {
    addUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    addCustomerTypes: (state, action) => {
      state.customer_types = action.payload
    },
    resetStateUser: (state) => {
      return {
        userInfo: {},
        customer_types: null,
        myTeam: null,
        suppliers: [],
        suppliersStatus: 'idle',
        suppliersError: null,
        reportItems: [],
        reportItemsStatus: 'idle',
        reportItemsError: null,
        myTeamStatus: 'idle',
        myTeamError: null,
      }
    }
  },
  extraReducers: (builder) => {
    builder

      // suppliers
      .addCase(fetchSuppliers.pending, (state) => {
        state.suppliersStatus = 'loading';
      })
      .addCase(fetchSuppliers.fulfilled, (state, action) => {
        state.suppliersStatus = 'succeeded';
        state.suppliers = action.payload;
        state.suppliersError = null; // Reset error state on success
      })
      .addCase(fetchSuppliers.rejected, (state, action) => {
        state.suppliersStatus = 'failed';
        state.suppliersError = action.payload || 'Failed to fetch suppliers';
      })

      // reportItems
      .addCase(fetchReportItems.pending, (state) => {
        state.reportItemsStatus = 'loading';
      })
      .addCase(fetchReportItems.fulfilled, (state, action) => {
        state.reportItemsStatus = 'succeeded';
        state.reportItems = action.payload;
        state.reportItemsError = null; // Reset error state on success
      })
      .addCase(fetchReportItems.rejected, (state, action) => {
        state.reportItemsStatus = 'failed';
        state.reportItemsError = action.payload || 'Failed to fetch report items';
      })

      // myTeam
      .addCase(fetchMyTeam.pending, (state) => {
        state.myTeamStatus = 'loading';
      })
      .addCase(fetchMyTeam.fulfilled, (state, action) => {
        state.myTeamStatus = 'succeeded';
        state.myTeam = action.payload;
        state.myTeamError = null; // Reset error state on success
      })
      .addCase(fetchMyTeam.rejected, (state, action) => {
        state.myTeamStatus = 'failed';
        state.myTeamError = action.payload || 'Failed to fetch my team';
      });

  },
});

export const { addUserInfo, addCustomerTypes, resetStateUser } = userSlice.actions
export default userSlice.reducer