import React from 'react'
import './NotificationPopupMobile.css'

export function showNotificationPopupMobile({
  title = '',
  message = '',
  actionButtonOnClick = () => {},
  actionButtonColor = 'green',
  actionButtonText = '',
}) {
  const popupContainer = document.querySelector('.popout_container__mobile--notification')
  
  if (popupContainer) {
    // Set the dynamic content
    popupContainer.querySelector('.notification-popup__elements__title--mobile h3').innerText = title
    popupContainer.querySelector('.notification-popup__elements__text--mobile').innerText = message
    
    const actionButton = popupContainer.querySelector('.notification-popup__buttons button:last-child')
    actionButton.innerText = actionButtonText
    actionButton.className = actionButtonColor
    actionButton.onclick = () => {
      actionButtonOnClick()
      closeNotificationPopupMobile()
    }

    const cancelButton = popupContainer.querySelector('.notification-popup__buttons button:first-child')
    cancelButton.onclick = closeNotificationPopupMobile

    popupContainer.classList.add('active')
  }
}

export function closeNotificationPopupMobile() {
  const popupContainer = document.querySelector('.popout_container__mobile--notification')
  if (popupContainer) {
    popupContainer.classList.remove('active')
  }
}

function NotificationPopupMobile({ children }) {
  return (
    <div className="popout_container__mobile--notification">
      {children || (
        <div className="notification-popup__elements--mobile">
          <div className="notification-popup__elements__title--mobile">
            <h3></h3>
          </div>
          <div className="notification-popup__elements__text--mobile">
          </div>
          <div className='notification-popup__buttons'>
            <button>Go back</button>
            <button>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default NotificationPopupMobile