import React, {useState, useEffect} from "react"
import httpClient from "../httpClient"
import "./Header.css"
import { useSelector, useDispatch } from "react-redux"

function Header(props) {
  const [user, setUser] = useState({email: "", id: ""})
  const [error, setError] = useState(false)
  const [headerMessage, setHeaderMessage] = useState("")
  
  const userInfo = useSelector(state => state.user.userInfo)
  let message 

  switch (userInfo.type) {
    case "salesforce":
      message = "Welcome back, deal closer! Remember, rejection is just a 'yes' in disguise."
      break
    case "manager":
      message = "Welcome, Brand Maestro! Time to conduct your symphony of products."
      break
    case "admin":
      message = "You are a super user. Use your power wisely!"
      break
    default:
      message = "🤖"
  }

  return (
    <div className="template__header" id="header-desktop">
      <div id="header-title" className="hide">
        <h1>Hi {userInfo.first_name}! 🥷</h1>
        <p className="header--p">{message}</p>
      </div>
      {props.children}
    </div>
)}

export default Header
