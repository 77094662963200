import React, { useEffect, useState, useRef } from 'react'
import httpClient from "../../httpClient"
import './CustomerPageMobile.css'
import { useSelector, useDispatch } from "react-redux"
import MenuBarMobile from '../../components/mobile/MenuBarMobile'
import { Link, useLocation, useNavigate } from "react-router-dom"
import LoadingSpinner from "../../components/placeholders/LoadingSpinner"
import { PiCaretRightThin } from "react-icons/pi"
import CircularProgress from '@mui/material/CircularProgress'
import { PiCaretDoubleDownThin } from "react-icons/pi"
import PopupwindowCustomerInfoMobile from '../../components/mobile/PopupwindowCustomerInfoMobile'
import PopupwindowCreateCustomerMobile from '../../components/mobile/PopupwindowCreateCustomerMobile'
import PopupwindowFiltersMobile from '../../components/mobile/PopupwindowFiltersMobile'
import { CiFilter } from "react-icons/ci"
import { PiPlusThin } from "react-icons/pi"

import {
  updateFilters,
  updateCustomerList,
  resetStateCustomerPage,
  updateTotalPagesCustomers,
  updateArrayOfCitiesCustomers,
  updateArrayOfPostalCodes,
  updateMobilePage
} from "../../store/customerPage"

function CustomerPageMobile() {
  const [loading, setLoading] = useState(false)
  const [loadingList, setLoadingList] = useState(false)
  const [limit, setLimit] = useState(25)
  const [hasMore, setHasMore] = useState(true)
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [createCustomerPopupIsActive, setCreateCustomerPopupIsActive] = useState(false)
  const [customerInfoPopupIsActive, setCustomerInfoPopupIsActive] = useState(false)
  const [filtersPopupIsActive, setFiltersPopupIsActive] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userInfo = useSelector(state => state.user.userInfo)
  const filters = useSelector(state => state.customerPage.filters)
  const customers = useSelector(state => state.customerPage.customerList)
  const totalPages = useSelector(state => state.customerPage.updateTotalPagesCustomers)
  const arrayOfCities = useSelector(state => state.customerPage.arrayOfCitiesCustomers)
  const arrayOfPostalCodes = useSelector(state => state.customerPage.arrayOfPostalCodes)
  const page = useSelector(state => state.customerPage.mobilePage)

  const previousFilters = useRef(filters)
  const scrollForMoreRef = useRef(null)

  const fetchCustomers = async (type=null) => {
    if (loadingList) return // Prevent multiple calls

    if (type == 'list') {
      setLoadingList(true)
    } else {
      setLoading(true)
    }

    try {
      const requestData = {
        listType: filters.customersListType,
        filters: {
          city: filters.cityFilter,
          postal_code: filters.postalCodeFilter,
          type: filters.typeFilter,
          sub_type: filters.subTypeFilter,
          search_word: filters.searchWord,
          b2c: filters.filterB2C,
        },
        sort: filters.sortFilter,
        sortAscending: filters.sortAscending,
      }

      const responseCustomers = await httpClient.post(process.env.REACT_APP_API_URL + `/api/users/get?page=${page}&limit=${limit}&cumulativePagination=${true}`, requestData)

      const hasMoreData = responseCustomers.data[4]
      dispatch(updateCustomerList(responseCustomers.data[0]))

      if (!hasMoreData) {
        setHasMore(false)
      } else {
        setHasMore(true)
        dispatch(updateMobilePage(page + 1))
      }

      dispatch(updateTotalPagesCustomers(responseCustomers.data[1]))
      dispatch(updateArrayOfCitiesCustomers(responseCustomers.data[2]))
      dispatch(updateArrayOfPostalCodes(responseCustomers.data[3]))
    } catch (error) {
      console.error('Error fetching customers:', error)
    } finally {
      setLoading(false)
      setLoadingList(false)
    }
  }

  useEffect(() => {
    if (customers.length == 0) {
      setLoading(true)
      fetchCustomers()
    }
  }, [])

  useEffect(() => {
    const handleIntersect = (entries) => {
      if (entries[0].isIntersecting && hasMore && !loadingList) {
        fetchCustomers('list')
      }
    }

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.95 // Trigger when the entire element is in view
    }

    const observer = new IntersectionObserver(handleIntersect, options)

    if (scrollForMoreRef.current) {
      observer.observe(scrollForMoreRef.current)
    }

    return () => {
      if (scrollForMoreRef.current) {
        observer.unobserve(scrollForMoreRef.current)
      }
    }
  }, [hasMore, loadingList])

  const resetCustomerState = () => {
    dispatch(resetStateCustomerPage())
    dispatch(updateMobilePage(1))
  }

  useEffect(() => {
    const previousFiltersDict = previousFilters.current

    // Compare the current filters with the previous filters
    if (JSON.stringify(previousFiltersDict) !== JSON.stringify(filters)) {
      if (previousFiltersDict.page == filters.page) {
        // if page did not change, page has to be reset to 1 (because a filter changed)
        dispatch(updateMobilePage(1))
      }

      fetchCustomers()
        .then(() => {
          setLoading(false)
        })
        .catch(error => {
          console.error('Error fetching customers:', error)
          setLoading(false)
        })

      // Update previous filters to current filters
      previousFilters.current = filters
    }
  }, [filters])

  const handleOnClickCustomer = (e, customerID) => {
    const customer = customers.find(customer => customer.id == customerID)
    setSelectedCustomer(customer)

    setCustomerInfoPopupIsActive(true)
  }

  return (
    <div className='template--mobile'>
      <PopupwindowCustomerInfoMobile
        selectedCustomer={selectedCustomer}
        fetchCustomers={resetCustomerState}
        setIsActive={setCustomerInfoPopupIsActive}
        isActive={customerInfoPopupIsActive}
      />
      <PopupwindowCreateCustomerMobile
        fetchCustomers={resetCustomerState}
        isActive={createCustomerPopupIsActive}
        setIsActive={setCreateCustomerPopupIsActive}
        redirectToCustomerInfo={true}
        setCustomer={setSelectedCustomer}
        setCustomerInfoPopupIsActive={setCustomerInfoPopupIsActive}
      />
      <PopupwindowFiltersMobile
        id={"popup-window-filters--customers-page"}
        isActive={filtersPopupIsActive}
        setIsActive={setFiltersPopupIsActive}
        filters={filters}
        updateFilters={updateFilters}
        refreshState={resetCustomerState}
        arrayOfCities={arrayOfCities}
        arrayOfPostalCodes={arrayOfPostalCodes}
      />

      <div className='template__body--mobile' id='mobile-customer-page'>
        <div className='body__section--transparent' id='header--mobile'>
          <h1>Customers</h1>
          <div className='header__buttons'>
            <div className='header__button' onClick={() => setFiltersPopupIsActive(!filtersPopupIsActive)}><CiFilter /></div>
            <div className='header__button' onClick={() => setCreateCustomerPopupIsActive(true)}><PiPlusThin /></div>
          </div>
        </div>
        <div className='body__section--transparent' id='customer-table'>
          {
            loading ?
              <div className='circular_loading_spinner__wrapper'><CircularProgress color='inherit' size="2rem" /></div>
              :
              <div className='table no-scrollbar'>
                {
                  customers.map((customer, index) => (
                    <div className='table__row_wrapper' key={index} onClick={(e) => handleOnClickCustomer(e, customer.id)} >
                      <div className='table__row-4--mobile'>
                        <div className='table__row--field'>{customer.business_name}</div>
                        <div className='table__row--field'>{customer.delivery_address_place}</div>
                        <div className='table__row--field'>{customer.first_name != null ? `${customer.first_name} ${customer.last_name}` : ""}</div>
                        <div className='table__row--field'>{customer.tel_number}</div>
                      </div>
                      <div className='table__row--field--arrow'><PiCaretRightThin /></div>
                    </div>
                  ))
                }
                <div className="scroll_for_more__element--mobile" ref={scrollForMoreRef}>
                  {
                    hasMore ?
                      <>
                        {
                          loadingList ?
                            <CircularProgress color="inherit" size="2rem" /> :
                            <div className='scroll_for_more__text'><PiCaretDoubleDownThin /> Scroll for more <PiCaretDoubleDownThin /></div>
                        }
                      </> : null
                  }
                </div>
              </div>
          }
        </div>
      </div>

      <MenuBarMobile />
    </div>
  )
}

export default CustomerPageMobile