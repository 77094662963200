import React, { useState, useEffect, useRef } from 'react'
import httpClient from '../../httpClient'
import { useSelector, useDispatch } from 'react-redux'
import './PopupwindowCreateOrderMobile.css'
import { PiCaretLeftThin } from "react-icons/pi"
import DropdownCustomerMobile from './DropdownCustomerMobile'
import AddProductForSalesMobile from './AddProductForSalesMobile'
import AlertPopupMobile from './AlertPopupMobile'
import PopupwindowCreateCustomerMobile from './PopupwindowCreateCustomerMobile'
import { showAlertPopupMobile } from '../helpers/NotificationHelpers'

function PopupwindowCreateOrderMobile({ id, isActive, setIsActive, fetchOrders }) {
	const [selectedCustomer, setSelectedCustomer] = useState({})
	const [productLineCounter, setProductLineCounter] = useState([1])
	const [selectedProducts, setSelectedProducts] = useState([])
	const [products, setProducts] = useState([])
	const [materials, setMaterials] = useState([])
	const [warehouses, setWarehouses] = useState([])
	const [selectedWarehouseForDelivery, setSelectedWarehouseForDelivery] = useState(null)
	const [newCustomerPopupIsActive, setNewCustomerPopupIsActive] = useState(false)

	const dispatch = useDispatch()
	const thisPopupContainerRef = useRef(null)
	const saveButtonRef = useRef(null)

	const productIDArray = [... new Set(selectedProducts.map(product => product.productID))]
	const filteredProducts = products.filter(product => !productIDArray.includes(String(product.id)))
	const materialIDArray = [... new Set(selectedProducts.map(material => material.materialID))]
	const filteredMaterials = materials.filter(material => !materialIDArray.includes(String(material.id)))


	const fetchProducts = async () => {
		const responseProducts = await httpClient.get(process.env.REACT_APP_API_URL + "/api/get-my-products")

		setProducts(responseProducts.data.filter(product => product.category != "POSM"))
		setMaterials(responseProducts.data.filter(product => product.category == "POSM"))
	}

	const fetchWarehouses = async () => {
		const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/brandowner/warehouses")
		const data = response.data

		const newWarehousesArray = data.map(warehouse => {
			return (
				{
					'id': warehouse.id,
					'name': warehouse.name
				}
			)
		})
		setWarehouses(newWarehousesArray)

		// if there is only 1 warehouse option, already set the selected warehouse for when delivery status gets changed
		if (newWarehousesArray.length == 1) {
			setSelectedWarehouseForDelivery(newWarehousesArray[0].id)
		}
	}

	useEffect(() => {
		fetchProducts()
		fetchWarehouses()
	}, [])

	const saveOrder = async () => {

		const allProductsValid = selectedProducts.every(product =>
			product.productID && product.vendor && product.amount !== undefined
		)

		if (!allProductsValid | !selectedCustomer) {
			showAlertPopupMobile({
				type: "error",
				message: "Please complete all required fields"
			})
			return
		}

		const data = {
			"customer": selectedCustomer.id,
			"products": selectedProducts,
		}

		const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/salesforce/order/send", data)
		console.log(response.data)

		// Clean up
		setSelectedCustomer({})
		setProductLineCounter([1])
		setSelectedProducts([])
		setIsActive(false)

		fetchOrders()
	}

	const handleClosePopup = () => {
		setIsActive(false)
	}

	const addProductLine = () => {
		setProductLineCounter([...productLineCounter, productLineCounter.length + 1])
	}

	const DeleteProductLine = (event, number) => {
		setProductLineCounter(currentArray => currentArray.filter(element => element != number))

		const index = number - 1
		const updatedArray = [...selectedProducts]
		updatedArray.splice(index, 1)
		setSelectedProducts(updatedArray)
	}

	return (
		<div className={`popup_window__container create-order ${isActive ? "active" : ""}`} id={id} ref={thisPopupContainerRef}>

			<PopupwindowCreateCustomerMobile
				isActive={newCustomerPopupIsActive}
				setIsActive={setNewCustomerPopupIsActive}
				setCustomer={setSelectedCustomer}
			/>

			<div className='popup_header'>
				<div className='go_back' onClick={handleClosePopup}><PiCaretLeftThin /></div>
				<button className='green-button-header' ref={saveButtonRef} onClick={saveOrder}>SAVE</button>
			</div>
			<div className='popup_body'>
				<h1>Create new order</h1>
				<DropdownCustomerMobile
					value={{ "id": selectedCustomer?.id, "name": selectedCustomer?.business_name }}
					setValue={setSelectedCustomer}
					createNewCustomer={setNewCustomerPopupIsActive}
				/>

				<div className='product_lines__container'></div>
				{
					productLineCounter.map((number, index) => (
						<div key={index}>
							<AddProductForSalesMobile
								onClickDelete={DeleteProductLine}
								index={index}
								number={number}
								selectedProducts={selectedProducts}
								setSelectedProducts={setSelectedProducts}
								products={filteredProducts}
								materials={filteredMaterials}
								customerID={selectedCustomer.id}
								warehouses={warehouses}
							/>
						</div>
					))
				}
				<button className='add_button' onClick={addProductLine}>+</button>
			</div>
		</div>
	)
}

export default PopupwindowCreateOrderMobile

PopupwindowCreateOrderMobile.defaultProps = {
	id: "popup-window-mobile,"
}

