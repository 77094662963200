import React, { useState, useEffect } from "react"
import httpClient from "../httpClient"
import "./MenuBar.css"
import MenuBrandOwner from "./MenuBrandOwner"
import MenuSalesForce from "./MenuSalesForce"
import MenuAdmin from "./MenuAdmin"
import { useSelector, useDispatch } from "react-redux"
import { setStateMiniMenu } from "../store/miniMenu"
import { Link } from "react-router-dom"
import { purgePersist, clearIndexedDB } from "./helpers/Update"
import { resetAppState } from "../store/store"

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined'

function MenuBar() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [image, setImage] = useState()

  const dispatch = useDispatch()

  const stoqupLogoBlack = require(`../assets/logos/Stoqup_logo_black.png`)
  const userInfo = useSelector(state => state.user.userInfo)
  const miniMenu = useSelector(state => state.miniMenu)

  useEffect(() => {
    const aside_menu = document.querySelector("#template__aside")
    miniMenu ? aside_menu.classList.add("mini") : aside_menu.classList.remove("mini")

    setImage(require(`../assets/pics/${userInfo.image_name}`))
  }, [])


  const renderPrimaryMenu = () => {
    switch (userInfo.type) {
      case "admin":
        return <MenuAdmin />
      case "manager":
        return <MenuBrandOwner />
      case "salesforce":
        return <MenuSalesForce />
      default:
        return null
    }
  }

  const handleMenuSlider = () => {
    const asideMenu = document.querySelector("#template__aside")
    const secondairyMenus = document.querySelectorAll(".sub-menu")

    dispatch(setStateMiniMenu(!miniMenu))
    asideMenu.classList.toggle("mini")
    secondairyMenus.forEach(item => {
      item.classList.remove("active")
    })
  }

  const logoutUser = async () => {
    try {
      // Reset app state and clear local storage
      resetAppState(false)

      // Wait for all cleanup operations to complete
      await Promise.all([
        purgePersist(),
        clearIndexedDB(),
        httpClient.post(process.env.REACT_APP_API_URL + "/api/logout")
      ])

    } catch (error) {
      console.error("Error during logout:", error)
      // Handle error (e.g., show an error message to the user)
    }
  }

  return (
    <div className="template__aside" id="template__aside">
      <button className="menu-Slider" onClick={handleMenuSlider}><KeyboardArrowLeftOutlinedIcon /></button>
      <img src={stoqupLogoBlack} alt="stoqup logo black" />
      <div className="menu">
        {renderPrimaryMenu()}
        <div className="secondairy__menu">
          <img className='avatar' src={image} />
          <hr />
          <Link to="/settings"><div className="menu__item"><SettingsOutlinedIcon /><p className="menu__title">Settings</p></div></Link>
          <div className="menu__item" onClick={logoutUser}><LogoutOutlinedIcon /><p className="menu__title">Logout</p></div>
        </div>
      </div>
    </div>
  )
}

export default MenuBar
