import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilters } from '../store/customerPage'
import './PopOutWindowAdvancedFilters.css'
import CloseIcon from '@mui/icons-material/Close'
import Slider from './Slider'
import CheckBoxCustomDetails from './CheckBoxCustomDetails'
import InputCustomDetails from './InputCustomDetails'
import DropDownCustomDetails from './DropDownCustomDetails'

function PopOutWindowAdvancedFilters({ popupID, onClose, maxHeight, isActive, setIsActive, width }) {
  const [localFilters, setLocalFilters] = useState({})
  const [localAdvancedFilters, setLocalAdvancedFilters] = useState({})
  const [customDetails, setCustomDetails] = useState([])

  const dispatch = useDispatch()
  const filters = useSelector(state => state.customerPage.filters)
  const advancedFilters = useSelector(state => state.customerPage.filters.advancedFilters)
  const reportItems = useSelector(state => state.user.reportItems)

  const closePopupWindow = () => {
    setIsActive(false)
  }

  useEffect(() => {
    const popup = document.getElementById(popupID)
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })
  }, [])

  useEffect(() => {
    setCustomDetails(Array.isArray(reportItems) ? reportItems : []);
  }, [reportItems])

  useEffect(() => {
    setLocalAdvancedFilters(prev => ({
      ...prev,
      customDetails: customDetails
    }))

  }, [customDetails])

  useEffect(() => {
    setLocalFilters(filters)
    setLocalAdvancedFilters(advancedFilters)
  }, [filters, advancedFilters])

  const handleHideB2C = () => {
    const updatedFilters = { ...localFilters }
    updatedFilters["filterB2C"] = !localFilters.filterB2C

    setLocalFilters(updatedFilters)
  }

  const handleSaveFilters = () => {
    dispatch(updateFilters({...localFilters, advancedFilters: localAdvancedFilters}))
    closePopupWindow()
  }

  const handleAdvancedFilter = (filter, type = "slider", defaultState = false) => {
    if (type === "slider") {
      setLocalAdvancedFilters(prev => ({
        ...prev,
        [filter]: !localAdvancedFilters[filter] || defaultState
      }))
    }
  }

  function renderCustomDetails(details) {
    if (!Array.isArray(details)) {
      console.warn('Expected details to be an array, received:', details);
      return null;
    }

    return details.map((detail, index) => {
      if (!detail) return null;
      
      switch (detail.type) {
        case 'checkmark':
          return <CheckBoxCustomDetails
            key={index}
            index={index}
            text={detail.title}
            defaultState={detail.value}
            state={customDetails}
            setState={setCustomDetails}
            textPosition="vert"
            margin="0"
          />
        case 'input':
          return <InputCustomDetails
            key={index}
            index={index}
            text={detail.title}
            defaultState={detail.value}
            state={customDetails}
            setState={setCustomDetails}
            inputBoxSize={100}
            fontSize="0.75rem"
            textPosition="vert"
          />
        case 'dropdown':
          return <DropDownCustomDetails
            key={index}
            index={index}
            title={detail.title}
            defaultState={detail.value}
            state={customDetails}
            setState={setCustomDetails}
            listArray={detail.dropdown_values}
            inherit={true}
            marginTop="0"
          />
        default:
          return null
      }
    })
  }

  const handleClearAll = () => {
    setLocalFilters({...localFilters, filterB2C: false})
    setLocalAdvancedFilters({})
    const clearedDetails = customDetails.map(detail => ({
      ...detail,
      value: ""
    }))
    setCustomDetails(clearedDetails)
  }

  return (
    <div
      className={`popout__container ${isActive ? "active" : ""}`}
      id={popupID}
      onClick={closePopupWindow}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          closePopupWindow()
        }
      }}
    >
      <div
        className='popout__box'
        style={{ maxHeight, width }}
        id={popupID}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon id="popup__close-icon" onClick={closePopupWindow} />
        <div className='popout__header'>
          <button className='popup__options-button' id='save-btn-advanced-filters' onClick={handleSaveFilters}>SAVE</button>
          <button className='options-button-header high-contrast' id='clear-btn-advanced-filters' onClick={handleClearAll}>Clear all</button>
        </div>
        <div className='popout__body'>
          <div className='advanced-filters__body'>
            <div className='sliders__container'>
              <Slider textColor="var(--secondairy-medium-gray)" size={1.2} fontSize={"0.75rem"} text="Hide B2C" onClickFunction={handleHideB2C} state={localFilters?.filterB2C} defaultState={localFilters?.filterB2C} />
              <Slider textColor="var(--secondairy-medium-gray)" size={1.2} fontSize={"0.75rem"} text="Is supplier" onClickFunction={() => handleAdvancedFilter("isSupplier")} state={localAdvancedFilters?.isSupplier} defaultState={localAdvancedFilters?.isSupplier} />
            </div>
            {
              customDetails.length != 0 ?
                <div className="report-items__container">
                  {
                    renderCustomDetails(customDetails)
                  }
                </div> : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopOutWindowAdvancedFilters

PopOutWindowAdvancedFilters.defaultProps = {
  onClose: () => console.log(""),
  overflow: "scroll",
  maxHeight: "75%",
  width: "50%"
}
