import React, { useState, useEffect, useRef } from "react"
import httpClient from "../httpClient"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { addUserInfo } from "../store/user"
import "./HomePageBrandOwner.css"
import Header from "../components/Header"
import MenuBar from "../components/MenuBar"
import ComingSoonOverlay from "../components/placeholders/ComingSoonOverlay"
import TotalSales from "../components/TotalSales"
import TodoToday from "../components/TodoToday"
import BestSellers from "../components/BestSellers"
import MySales from "../components/MySales"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import CircularProgress from '@mui/material/CircularProgress'
import { askPermissionAndSubscribe } from "../components/helpers/ServiceWorker"
import CustomersToContact from "../components/CustomersToContact"
function HomePageBrandOwner() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)
  const [activeClients, setActiveClients] = useState({})

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.user)
  const previousUserIdRef = useRef()

  const fetchUser = async () => {
    const responseUser = await httpClient.get(process.env.REACT_APP_API_URL + "/api/@me?type=full")
    setUser(responseUser.data)
  }

  useEffect(() => {
    fetchUser()
      .catch(err => {
        console.log(err)
        setError(true)
      })

    document.getElementById("header-title").classList.remove("hide")
  }, [])

  useEffect(() => {
    if (userInfo.id && userInfo.id !== previousUserIdRef.current) {
      previousUserIdRef.current = userInfo.id
      askPermissionAndSubscribe(userInfo.id)
    }
  }, [userInfo])

  return (
    <div className="template">
      {error ? window.location.href = "/" : ""}

      <Header />
      <MenuBar />
      <div className="template__body">
        <div className="body__home-brandowner">
          <div className="body__section" id="section1">
            <TotalSales/>
          </div>
          <div className="body__section" id="section2">
            <BestSellers />
          </div>
          <div className="body__section" id="section3">
            <MySales />
          </div>
          <div className="body__section" id="section4">
            <CustomersToContact userType="manager" />
          </div>
          <div className="body__section" id="section5">
            <TodoToday />
          </div>
        </div>
      </div>
    </div>
  )
}
export default HomePageBrandOwner