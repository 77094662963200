import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilters } from '../store/activitiesPage'
import './WeekCalendar.css'
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"
import CircularProgress from '@mui/material/CircularProgress'

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const WeekCalendar = ({ activities, loading, setCreateActivity, setSelectedActivity, setEditActivity }) => {
  const [currentDate, setCurrentDate] = useState(new Date())
  
  const dispatch = useDispatch()
  const filters = useSelector(state => state.activitiesPage.filters)
  const calendarView = useSelector(state => state.activitiesPage.filters.calendarView)

  useEffect(() => {
    if (!calendarView) return

    const startOfWeek = getStartOfWeek(currentDate)
    const endOfWeek = new Date(startOfWeek)
    endOfWeek.setDate(endOfWeek.getDate() + 6)
    endOfWeek.setHours(23, 59, 59, 999)

    dispatch(updateFilters({
      ...filters,
      calendarBounds: {
        startDate: startOfWeek.toISOString(),
        endDate: endOfWeek.toISOString()
      }
    }))
  }, [currentDate, calendarView, dispatch])

  // Function to get the start of the week (Monday)
  const getStartOfWeek = (date) => {
    const start = new Date(date)
    const day = start.getDay()
    const diff = start.getDate() - day + (day === 0 ? -6 : 1) // Adjust to Monday
    start.setDate(diff)
    return start
  }

  // Function to handle week navigation
  const handleNextWeek = () => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)))
  }

  const handlePreviousWeek = () => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)))
  }

  const handleToday = () => {
    setCurrentDate(new Date())
  }

  const startOfWeek = getStartOfWeek(currentDate)
  const weekDays = Array.from({ length: 7 }, (_, i) => {
    const day = new Date(startOfWeek)
    day.setDate(startOfWeek.getDate() + i)
    return day
  })

  const renderTimeSlots = () => {
    return Array.from({ length: 24 }, (_, hour) => (
      <div key={`time-${hour}`} className="time-slot">
        {`${hour.toString().padStart(2, '0')}:00`}
      </div>
    ))
  }

  const handleOnClickActivity = (event) => {
    const id = event.currentTarget.getAttribute("data-activity-id")

    setEditActivity(true)

    const activity = activities.find(obj => obj.id == id)
    setSelectedActivity(activity)

    setCreateActivity(true)
  }

  const renderActivitiesForDay = (dayIndex, dayActivities) => {
    // Sort activities by start time
    dayActivities.sort((a, b) => new Date(a.planned_date) - new Date(b.planned_date))

    // Calculate overlaps and assign columns
    const columns = []
    dayActivities.forEach(activity => {
      const startTime = new Date(activity.planned_date)
      const endTime = new Date(activity.planned_to_date)
      let column = 0
      while (columns[column]?.some(existingActivity => {
        const existingStart = new Date(existingActivity.planned_date)
        const existingEnd = new Date(existingActivity.planned_to_date)
        return (startTime < existingEnd && endTime > existingStart)
      })) {
        column++
      }
      if (!columns[column]) columns[column] = []
      columns[column].push(activity)
      activity.column = column
    })

    const maxColumns = columns.length

    // Render activities
    return dayActivities.map(activity => {
      const startTime = new Date(activity.planned_date)
      const endTime = new Date(activity.planned_to_date)
      const startMinutes = startTime.getHours() * 60 + startTime.getMinutes()
      const duration = (endTime - startTime) / (1000 * 60)

      // Calculate widths and positions within the day
      const columnWidth = 100 / maxColumns
      const left = `${activity.column * columnWidth}%`
      const width = `${columnWidth}%`

      // Add opacity if activity is done
      const opacity = activity.done ? 0.33 : 1

      // Set background color based on activity type
      let backgroundColor
      let borderColor

      switch (activity.type) {
        case 'Visit':
          backgroundColor = 'var(--ultra-light-primary-green)'
          borderColor = 'var(--primary-green)'
          break
        case 'Phone call':
          backgroundColor = 'var(--light-blue)'
          borderColor = 'var(--blue)'
          break
        case 'Task':
          backgroundColor = 'var(--light-red)'
          borderColor = 'var(--red)'
          break
        case 'Meeting':
          backgroundColor = 'var(--light-purple)'
          borderColor = 'var(--purple)'
          break
        case 'Tasting':
          backgroundColor = 'var(--special-gold-ultra-light-plus)'
          borderColor = 'var(--special-gold)'
          break
        default:
          backgroundColor = 'var(--ultra-light-primary-green)'
          borderColor = 'var(--primary-green)'
      }

      const style = {
        position: 'absolute',
        top: `${startMinutes}px`,
        height: `${duration}px`,
        left: left,
        width: width,
        backgroundColor: backgroundColor,
        border: `1px solid ${borderColor}`,
        zIndex: 10,
        opacity: opacity,
        cursor: 'pointer'
      }

      const formatTime = (date) => {
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })
      }

      return (
        <div key={activity.id} className="calendar-activity" style={style} onClick={handleOnClickActivity} data-activity-id={activity.id}>
          <div className='calendar-activity-time'>{`${formatTime(startTime)} - ${formatTime(endTime)}`}</div>
          <div className='calendar-activity-title'>{activity.title}</div>
        </div>
      )
    })
  }

  const renderActivities = () => {
    // Group activities by day
    const activitiesByDay = weekDays.map(() => [])

    activities.forEach(activity => {
      const startTime = new Date(activity.planned_date)
      const dayIndex = weekDays.findIndex(day =>
        day.toDateString() === startTime.toDateString()
      )
      if (dayIndex !== -1) {
        activitiesByDay[dayIndex].push({ ...activity })
      }
    })

    return weekDays.map((day, dayIndex) => (
      <div
        key={dayIndex}
        className={`calendar-day ${[0, 6].includes(day.getDay()) ? 'weekend' : ''}`}
      >
        {renderActivitiesForDay(dayIndex, activitiesByDay[dayIndex])}
      </div>
    ))
  }

  return (
    <div className="week-calendar">
      <div className="calendar-header">
        <h2 className="month-year-title">
          <div className="month-name">
            {startOfWeek.toLocaleDateString('en-US', { month: 'long' })}
          </div>
          <div className="year-number">
            {startOfWeek.toLocaleDateString('en-US', { year: 'numeric' })}
          </div>
        </h2>
        <div className="calendar-nav-buttons">
          <button className="nav-button" onClick={handlePreviousWeek}><BsChevronLeft /></button>
          <button className="today-button" onClick={handleToday}>Today</button>
          <button className="nav-button" onClick={handleNextWeek}><BsChevronRight /></button>
        </div>
      </div>
      <div className="calendar-grid">
        <div className="day-header">
          {weekDays.map((day, dayIndex) => (
            <div
              key={dayIndex}
              className={`day-header-item ${[0, 6].includes(day.getDay()) ? 'weekend' : ''}`}
            >
              <div className="day-name">
                {day.toLocaleDateString('en-US', { weekday: 'short' })}
              </div>
              <div className="day-number">
                {day.toLocaleDateString('en-US', { day: 'numeric' })}
              </div>
            </div>
          ))}
        </div>
        <div className="calendar-content-wrapper">
          <div className="time-slots">
            {renderTimeSlots()}
          </div>
          {
            loading ? <div className="loading-spinner"><CircularProgress color="inherit" /></div> :
              <div className="calendar-content">
                {renderActivities()}
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default WeekCalendar
