import React, { useState, useEffect, useRef } from 'react'
import './PopOutWindowCreateActivity.css'
import { useSelector, useDispatch } from "react-redux"
import { resetStateVisitReport, updateVisitReport } from "../store/visitReport"
import httpClient from "../httpClient"
import CloseIcon from '@mui/icons-material/Close'
import Dropdown from './Dropdown'
import DatePicker from './DatePicker'
import { activity_types } from '../assets/Data'
import Slider from './Slider'
import DropDownInputCustomer from './DropDownInputCustomer'
import { getRoundedDateHalfHour } from './helpers/FormatDate'
import { toast } from "react-toastify"

export function PopOutWindowCreateActivity({
  popupID, onClose, selectedCustomer, selectedCustomerID, setSelectedCustomerID,
  setNewCustomerPopupIsActive, fetchActivities, myBrands,
  editActivity, setEditActivity, selectedActivity, myTeam, keepCustomerState, isActive, setIsActive
}) {
  const [activityType, setActivityType] = useState("")
  const [dueDate, setDueDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [isDone, setIsDone] = useState(false)
  const [comment, setComment] = useState("")
  const [priority, setPriority] = useState(3)
  const [title, setTitle] = useState("")
  const [report, setReport] = useState("")
  const [moveToUndecided, setMoveToUndecided] = useState(false)
  const [moveToLost, setMoveToLost] = useState(false)
  const [assignedTo, setAssignedTo] = useState({ "id": null, "name": null })
  const [selectedSalesPipeActions, setSelectedSalesPipeActions] = useState({})
  const [localSelectedCustomer, setLocalSelectedCustomer] = useState()
  const [customers, setCustomers] = useState([])

  const refParentDiv = useRef(null)
  const reportDataRedux = useSelector(state => state.visitReport)
  const userInfo = useSelector(state => state.user.userInfo)
  const requester = userInfo.type

  const fetchCustomers = async () => {
    const responseCustomers = await httpClient.get(process.env.REACT_APP_API_URL + "/api/users/get?requester=" + requester)
    setCustomers(responseCustomers.data)
  }

  useEffect(() => {
    const popup = document.getElementById(popupID)
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })

    setDueDate(getRoundedDateHalfHour())

    fetchCustomers()
  }, [])

  useEffect(() => {
    selectedCustomer && setLocalSelectedCustomer(selectedCustomer)
  }, [selectedCustomer])

  useEffect(() => {
    if (editActivity) {
      setLocalSelectedCustomer(selectedActivity.customer_id.business_name)
      setAssignedTo({
        ...assignedTo,
        "id": selectedActivity.sales_rep_id.id,
        "name": selectedActivity.sales_rep_id.first_name,
      })
      setActivityType(selectedActivity.type)
      setDueDate(selectedActivity.planned_date)
      setToDate(selectedActivity.planned_to_date)
      setIsDone(selectedActivity.done)
      setComment(selectedActivity.comment)
      setPriority(selectedActivity.priority)
      setTitle(selectedActivity.title)

      const reportObj = {}
      selectedActivity.report.forEach(report => {
        reportObj[report.brandowner_id.id] = { "report": report.report }
      })
      setReport(reportObj)
    } else {
      setActivityType("")
      setDueDate(null)
      setToDate(null)
      setIsDone(false)
      setComment("")
      setPriority(3)
      setTitle("")
      setReport({})
      setAssignedTo({ "id": null, "name": null })
    }
  }, [editActivity])

  useEffect(() => {

    if (editActivity) {
      return
    }
    switch (activityType) {
      case "Visit":
      case "Tasting":
      case "Meeting":
        setTitle(`${activityType} at ${localSelectedCustomer}`)
        break
      case "Task":
      case "Note":
        setTitle(`${activityType} for ${localSelectedCustomer}`)
        break
      case "Phone call":
        setTitle(`${activityType} with ${localSelectedCustomer}`)
        break
      case "Email":
        setTitle(`${activityType} of ${localSelectedCustomer}`)
        break
      default:
        setTitle("")

    }

  }, [activityType, localSelectedCustomer])

  useEffect(() => {
    setMoveToLost(false)
    setMoveToUndecided(false)
  }, [isDone])

  useEffect(() => {
    if (dueDate && !editActivity) {
      const newToDate = new Date(dueDate)
      newToDate.setMinutes(newToDate.getMinutes() + 60)
      setToDate(newToDate.getTime())
    }
  }, [dueDate])

  const cleanState = () => {
    setActivityType("")
    setDueDate(null)
    setToDate(null)
    setIsDone(false)
    setComment("")
    setPriority(3)
    setTitle("")
    setReport({})
    setMoveToLost(false)
    setMoveToUndecided(false)
    setSelectedSalesPipeActions({})
    setEditActivity(false)
    setAssignedTo({ "id": null, "name": null })
    const allPriorityBoxes = document.querySelectorAll(".priority_option")
    allPriorityBoxes.forEach(box => box.classList.remove("selected"))

    if (!keepCustomerState) {
      setLocalSelectedCustomer("")
      setSelectedCustomerID("")
    }
  }

  const closePopupWindow = () => {
    cleanState()
    onClose()
    setIsActive(false)
  }

  const handleSaveActivity = async () => {
    const saveButton = document.querySelector('#save-btn-create-activity')
    saveButton.disabled = true

    const data = {
      activityType, dueDate, toDate, isDone,
      selectedCustomerID, comment, priority, title, report,
      moveToLost, moveToUndecided, assignedTo
    }

    if (!activityType || !selectedCustomerID || (!dueDate && activityType !== "Note")) {
      toast.error("Please fill out all required data.", { theme: "dark" })
      saveButton.disabled = false
      return
    }

    // Check if toDate is before dueDate
    if (toDate && dueDate && new Date(toDate) < new Date(dueDate)) {
      toast.error("End date cannot be before start date.", { theme: "dark" })
      saveButton.disabled = false
      return
    }

    let response
    if (editActivity) {
      response = await toast.promise(
        httpClient.post(
          `${process.env.REACT_APP_API_URL}/api/activities/update?activityID=${selectedActivity.id}`,
          data,
          { responseType: 'arraybuffer' }
        ),
        {
          "success": "Activity successfully updated 🎯",
          "pending": "Updating the activity...",
          "error": "Something went wrong 😢"
        }
      )
    } else {
      response = await toast.promise(
        httpClient.post(
          `${process.env.REACT_APP_API_URL}/api/activities/create`,
          data,
          { responseType: 'arraybuffer' }
        ),
        {
          "success": "Activity successfully created 🎯",
          "pending": "Creating the activity...",
          "error": "Something went wrong 😢"
        }
      )
    }

    // Clean up state and close popup
    cleanState()
    closePopupWindow()

    // Fetch activities and wait for it to complete
    await fetchActivities()

    saveButton.disabled = false

    // Check if the response contains an .ics file
    const contentType = response.headers['content-type']
    if (contentType && contentType.includes('text/calendar')) {
      const blob = new Blob([response.data], { type: 'text/calendar;charset=utf-8' })

      // Use FileReader to read the blob as a data URL
      const reader = new FileReader()
      reader.onload = function (event) {
        const downloadLink = document.createElement('a')
        downloadLink.href = event.target.result
        downloadLink.setAttribute('download', 'event.ics')
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      }
      reader.readAsDataURL(blob)
    } else {
      console.log(response.data)
    }
  }

  const handleOnClickDropdownItemCustomer = (event) => {
    setLocalSelectedCustomer(event.currentTarget.firstChild.textContent)
    setSelectedCustomerID(event.currentTarget.getAttribute("id"))
  }

  const handleOnclickPriority = (event, number) => {
    const allPriorityBoxes = document.querySelectorAll(".priority_option")
    const priorityBox = event.currentTarget

    allPriorityBoxes.forEach(box => box.classList.remove("selected"))

    priorityBox.classList.add("selected")
    setPriority(number)
  }

  const handleClickSalesPipeActionBtn = (brand_id, type) => {
    const newReport = { ...report }

    if (type === "undecided") {
      if (!newReport[brand_id]) {
        // Initialize brand_id object if it doesn't exist
        newReport[brand_id] = {}
      }

      if (newReport[brand_id].hasOwnProperty("undecided")) {
        // Toggle "undecided" if it exists
        newReport[brand_id]["undecided"] = !newReport[brand_id]["undecided"]
      } else {
        // Initialize "undecided" if it doesn't exist
        newReport[brand_id]["undecided"] = true
      }

      // Reset 'lost' to false if it exists
      if (newReport[brand_id]["lost"]) {
        newReport[brand_id]["lost"] = false
      }
    }

    if (type === "lost") {
      if (!newReport[brand_id]) {
        // Initialize brand_id object if it doesn't exist
        newReport[brand_id] = {}
      }

      if (newReport[brand_id].hasOwnProperty("lost")) {
        // Toggle "lost" if it exists
        newReport[brand_id]["lost"] = !newReport[brand_id]["lost"]
      } else {
        // Initialize "lost" if it doesn't exist
        newReport[brand_id]["lost"] = true
      }

      // Reset 'undecided" to false if it exists
      if (newReport[brand_id]["undecided"]) {
        newReport[brand_id]["undecided"] = false
      }
    }

    setReport(newReport)

    // Update the selected actions state
    setSelectedSalesPipeActions(prev => {
      // Deselect if the same button is clicked again
      if (prev[brand_id] === type) {
        return { ...prev, [brand_id]: null };
      }
      return { ...prev, [brand_id]: type };
    })

  }

  const handleSetReport = (event, brand_id) => {
    const newReport = { ...report }
    newReport[brand_id] = { "report": event.currentTarget.value }
    setReport(newReport)
  }

  const handleCopyToAll = () => {
    const firstKeyReport = Object.keys(report)[0]
    const firstReport = report[firstKeyReport]
    const firstKeySelectedSalesPipeActions = Object.keys(selectedSalesPipeActions)[0]
    const firstSelectedSalesPipeActions = selectedSalesPipeActions[firstKeySelectedSalesPipeActions]

    const newReport = { ...report }
    const newSelectedSalesPipeActions = { ...selectedSalesPipeActions }

    myBrands.forEach(brand => {
      newReport[brand.id] = firstReport
      newSelectedSalesPipeActions[brand.id] = firstSelectedSalesPipeActions

    })

    setReport(newReport)
    setSelectedSalesPipeActions(newSelectedSalesPipeActions)
  }

  const handleDeleteActivity = async () => {
    const response = await toast.promise(
      httpClient.delete(`${process.env.REACT_APP_API_URL}/api/activities/delete?activityID=${selectedActivity.id}`, { responseType: 'arraybuffer' }),
      {
        "success": "Activity successfully deleted",
        "pending": "Deleting the activity...",
        "error": "Something went wrong 😢"
      }
    )

    // Close the popup window
    closePopupWindow()
    // Clean up state
    cleanState()
    // Fetch activities to refresh the list
    await fetchActivities()

    // Check if the response contains an .ics file
    const contentType = response.headers['content-type']
    if (contentType && contentType.includes('text/calendar')) {
      const blob = new Blob([response.data], { type: 'text/calendar;charset=utf-8' })

      // Use FileReader to read the blob as a data URL
      const reader = new FileReader()
      reader.onload = function (event) {
        const downloadLink = document.createElement('a')
        downloadLink.href = event.target.result
        downloadLink.setAttribute('download', 'event.ics')
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      }
      reader.readAsDataURL(blob)
    } else {
      console.log(response.data)
    }
  }

  const handleSetAssignedTo = (name, index, dataIndex) => {
    const teamMember = {
      "id": dataIndex,
      "name": name,
    }

    setAssignedTo(teamMember)
  }

  return (
    <div className={`popout__container ${isActive ? "active" : ""}`} onClick={closePopupWindow} id={popupID}>
      <div className='popout__box' onClick={(event) => event.stopPropagation()}>
        <CloseIcon id="popup__close-icon" onClick={closePopupWindow} />
        <div className='popout__header'>
          {
            editActivity ?
              <>
                <button className='popup__options-button' id='save-btn-create-activity' onClick={handleSaveActivity}>SAVE</button>
                <button className='options-button-header--red' id='delete-btn-create-activity' onClick={handleDeleteActivity}>Delete</button>
              </> :
              <button className='popup__options-button' id='save-btn-create-activity' onClick={handleSaveActivity}>SAVE</button>
          }
          {
            myBrands.length > 1 &&
            isDone &&
            <>
              <button className='options-button-header high-contrast' id='copy-btn-create-activity' onClick={handleCopyToAll}>Copy to all</button>
            </>
          }
        </div>
        <div className='popout__body' ref={refParentDiv}>
          {
            !["Note"].includes(activityType) &&
            <div className='wdth75'>
              <Slider
                state={isDone}
                onClickFunction={() => setIsDone(!isDone)}
                text={"Mark as done"}
                fontSize={"0.75rem"}
                size={1.3}
                style={"h2"}
              />
            </div>
          }

          <h2 className='form__section_title'>Customer</h2>
          <DropDownInputCustomer
            idList={"activities__dropdownList--customer"}
            placeholderText=""
            listArray={customers}
            onClickItem={handleOnClickDropdownItemCustomer}
            value={localSelectedCustomer}
            setValue={setLocalSelectedCustomer}
            createNewCustomer={setNewCustomerPopupIsActive}
          />

          {
            ['manager'].includes(userInfo.type) &&
            <>
              <h2 className='form__section_title'>Assign to</h2>
              <Dropdown
                listArray={myTeam}
                state={assignedTo}
                setState={handleSetAssignedTo}
                inherit={true}
                height={"40px"}
                marginTop={"0"}
              />
            </>
          }

          <h2 className='form__section_title'>Type</h2>
          <Dropdown
            listArray={Object.keys(activity_types)}
            state={activityType}
            setState={setActivityType}
            inherit={true}
            height={"40px"}
            marginTop={"0"}
          />

          {
            ["Note"].includes(activityType) ?
              null :
              ["Visit", "Meeting", "Tasting"].includes(activityType) ?
                <>
                  <h2 className='form__section_title'>{isDone ? "From" : "Planned from"}</h2>
                  <DatePicker
                    date={dueDate}
                    setDate={setDueDate}
                    placeholder={"Select date"}
                    enableTime={true}
                    altInput={true}
                  />

                  <h2 className='form__section_title'>{isDone ? "To" : "Planned to"}</h2>
                  <DatePicker
                    date={toDate}
                    setDate={setToDate}
                    placeholder={"Select date"}
                    enableTime={true}
                    altInput={true}
                  />
                </> :
                <>
                  <h2 className='form__section_title'>{isDone ? "Date" : "Due date"}</h2>
                  <DatePicker
                    date={dueDate}
                    setDate={setDueDate}
                    placeholder={"Select date"}
                    enableTime={true}
                    altInput={true}
                  />
                </>
          }

          <h2 className='form__section_title'>Title</h2>
          <input className='input_field__input_box--text big' value={title} onChange={(event) => setTitle(event.currentTarget.value)} maxLength={"100"} />


          {
            ["Note"].includes(activityType) ?
              <>
                <h2 className='form__section_title'>Comment</h2>
                <textarea className='input_field__input_box--textarea' value={comment} onChange={(event) => setComment(event.currentTarget.value)} maxLength={"1000"} />
              </> : isDone ?
                <>
                  {
                    myBrands.map((brand, index) => (
                      <div key={index}>
                        <h2 className='form__section_title'>Report for {brand.business_name}</h2>
                        <textarea className='input_field__input_box--textarea' value={report[brand.id] ? report[brand.id]["report"] : ""} onChange={(event) => handleSetReport(event, brand.id)} maxLength={"1000"} />

                        <div className='action-buttons' id={`sales-pipe-buttons-${index}`}>
                          <button className={`gray-action-button selecting wdth100 hght100 ${selectedSalesPipeActions[brand.id] === 'undecided' ? 'selected' : ''}`} onClick={() => handleClickSalesPipeActionBtn(brand.id, "undecided")}>Move to undecided</button>
                          <button className={`red-action-button selecting wdth100 hght100 ${selectedSalesPipeActions[brand.id] === 'lost' ? 'selected' : ''}`} onClick={() => handleClickSalesPipeActionBtn(brand.id, "lost")}>Move to lost</button>
                        </div>
                      </div>
                    ))
                  }
                </> :
                <>
                  <h2 className='form__section_title'>Comment</h2>
                  <textarea className='input_field__input_box--textarea' value={comment} onChange={(event) => setComment(event.currentTarget.value)} maxLength={"1000"} />
                </>
          }

          {
            !["Note"].includes(activityType) & !isDone ?
              <>
                <h2 className='form__section_title'>Priority</h2>
                <div className='priority_container'>
                  <div className='priority_title important'>important</div>
                  <div className='priority_title not-important'>not important</div>
                  <div className='priority_title urgent'>urgent</div>
                  <div className='priority_title not-urgent'>not urgent</div>

                  <div className='priority_options'>
                    <div className='priority_option one' onClick={(event) => handleOnclickPriority(event, 1)}>
                      <div className='priority_option_content'>
                        1
                      </div>
                    </div>
                    <div className='priority_option two' onClick={(event) => handleOnclickPriority(event, 2)}>
                      <div className='priority_option_content'>
                        2
                      </div>
                    </div>
                    <div className='priority_option three' onClick={(event) => handleOnclickPriority(event, 3)}>
                      <div className='priority_option_content'>
                        3
                      </div>
                    </div>
                    <div className='priority_option four' onClick={(event) => handleOnclickPriority(event, 4)}>
                      <div className='priority_option_content'>
                        4
                      </div>
                    </div>
                  </div>
                </div>
              </> : null
          }


        </div>
      </div>
    </div>
  )
}

PopOutWindowCreateActivity.defaultProps = {
  onClose: () => console.log(""),
  overflow: "scroll",
  myTeam: []
}