import React, { useEffect, useState, useRef } from 'react'
import httpClient from "../../httpClient"
import './ActivitiesPageMobile.css'
import { useSelector, useDispatch } from "react-redux"
import MenuBarMobile from '../../components/mobile/MenuBarMobile'
import { CiFilter } from "react-icons/ci"
import { PiPlusThin } from "react-icons/pi"
import { updateFilters, 
  updateActivitiesList, 
  resetStateActivitiesPage, 
  updateMobilePage,
  updateNeedsReload
} from "../../store/activitiesPage"
import CircularProgress from '@mui/material/CircularProgress'
import LoadingSpinner from "../../components/placeholders/LoadingSpinner"
import PopupwindowFiltersMobile from '../../components/mobile/PopupwindowFiltersMobile'
import CheckBox from '../../components/CheckBox'
import { PiCaretRightThin } from "react-icons/pi"
import { PiCaretDoubleDownThin } from "react-icons/pi"
import { activity_types } from '../../assets/Data'
import { activity_types_svgs } from '../../assets/Data'
import { formatDate, formatDateWithTime } from "../../components/helpers/FormatDate"
import PopupwindowCreateActivityMobile from '../../components/mobile/PopupwindowCreateActivityMobile'
import { VscRefresh } from "react-icons/vsc"

function ActivitiesPageMobile() {
  const [loading, setLoading] = useState(false)
  const [loadingList, setLoadingList] = useState(false)
  const [filtersPopupIsActive, setFiltersPopupIsActive] = useState(false)
  const [createActivityPopupIsActive, setCreateActivityPopupIsActive] = useState(false)
  const [limit, setLimit] = useState(15)
  const [hasMore, setHasMore] = useState(true)
  const [myBrands, setMyBrands] = useState([])
  const [selectedCustomerID, setSelectedCustomerID] = useState("")
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [editActivity, setEditActivity] = useState(false)
  const [creatReport, setCreateReport] = useState(false)
  const [newCustomerPopupIsActive, setNewCustomerPopupIsActive] = useState(false)

  const dispatch = useDispatch()

  const userInfo = useSelector(state => state.user.userInfo)
  const activities = useSelector(state => state.activitiesPage.activitiesList)
  const filters = useSelector(state => state.activitiesPage.filters)
  const page = useSelector(state => state.activitiesPage.mobilePage)
  const myTeam = useSelector(state => state.user.myTeam)
  const needsReload = useSelector(state => state.activitiesPage.needsReload)
  const scrollForMoreRef = useRef(null)
  const previousFilters = useRef(filters)

  const activityTypesArray = Object.keys(activity_types)
  const prioritiesArray = [
    { id: 1, name: "1: important & urgent" },
    { id: 2, name: "2: important & not urgent" },
    { id: 3, name: "3: not important & urgent" },
    { id: 4, name: "4: not important & not urgent" }
  ]
  const now = new Date()

  const fetchActivities = async (reload = false) => {
    if (loadingList) return // Prevent multiple calls
    setLoadingList(true)
    if (reload) {
      setLoading(true)
    }
  
    try {
      const data = { filters }
      const response = await httpClient.post(
        `${process.env.REACT_APP_API_URL}/api/activities/get?page=${page}&limit=${limit}&listType=${filters.listType}&cumulativePagination=true`, 
        data
      )
      dispatch(updateActivitiesList(response.data.activities))
      
      if (!response.data.hasMore) {
        setHasMore(false)
      } else {
        setHasMore(true)
        dispatch(updateMobilePage(page + 1))
      }
    } catch (error) {
      console.error('Error fetching activities:', error)
    } finally {
      setLoading(false)
      setLoadingList(false)
    }
  }

  const fetchMyBrands = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/salesforce/get-my-brandowners`)

    setMyBrands(response.data)
  }

  useEffect(() => {
    if (activities.length == 0) {
      fetchActivities()
    }
    fetchMyBrands()
  }, [])

  useEffect(() => {
    const handleIntersect = (entries) => {
      if (entries[0].isIntersecting && hasMore && !loadingList) {
        fetchActivities()
      }
    }
  
    const options = {
      root: null, 
      rootMargin: "0px",
      threshold: 0.95 // Trigger when the entire element is in view
    }
  
    const observer = new IntersectionObserver(handleIntersect, options)
  
    if (scrollForMoreRef.current) {
      observer.observe(scrollForMoreRef.current)
    }
  
    return () => {
      if (scrollForMoreRef.current) {
        observer.unobserve(scrollForMoreRef.current)
      }
    }
  }, [hasMore, loadingList])

  useEffect(() => {
    if (needsReload) {
      fetchActivities()
      dispatch(updateNeedsReload(false))
    }
  }, [needsReload])

  useEffect(() => {
    const previousFiltersDict = previousFilters.current

    // Compare the current filters with the previous filters
    if (JSON.stringify(previousFiltersDict) !== JSON.stringify(filters)) {
      if (previousFiltersDict.page == filters.page) {
        // if page did not change, page has to be reset to 1 (because a filter changed)
        dispatch(updateMobilePage(1))
        setLoading(true)
      }

      fetchActivities()
        .then(() => {
          setLoading(false)
        })
        .catch(error => {
          console.error('Error fetching activities:', error)
          setLoading(false)
        })

      // Update previous filters to current filters
      previousFilters.current = filters
    }
  }, [filters])

  const resetActivitiesState = () => {
    dispatch(resetStateActivitiesPage())
    dispatch(updateMobilePage(1))
  }

  const handleEdit = (event, id) => {
    event.stopPropagation()

    setEditActivity(true)

    const activity = activities.find(obj => obj.id == id)
    setSelectedActivity(activity)

    setCreateActivityPopupIsActive(true)
  }

  const handlePreCheckDone = async (id) => {
    const activityIndex = activities.findIndex(obj => obj.id == id)
    const activity = activities[activityIndex]
    console.log(activity)
  
    if (activity.done) {
      setLoading(true)
  
      const data = {
        ids: [id]
      }
  
      const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/activities/change-done-status?id=${id}`, data)
      console.log(response.data)
  
      fetchActivities()
        .then(() => {
          setLoading(false)
        })
        .catch(error => {
          console.error('Error fetching activities:', error)
          setLoading(false)
        })
  
    } else {
      const updatedActivity = { ...activity, done: true }
      const updatedActivities = [...activities]
      updatedActivities[activityIndex] = updatedActivity
  
      // Update state with the new activities array
      dispatch(updateActivitiesList(updatedActivities))
  
      setCreateReport(true)
      setCreateActivityPopupIsActive(true)
      setSelectedActivity(updatedActivity)
    }
  }

  return (
    <div className='template--mobile'>

      <PopupwindowCreateActivityMobile
        isActive={createActivityPopupIsActive}
        setIsActive={setCreateActivityPopupIsActive}
        popupID={"popout-create-activity"}
        selectedCustomerID={selectedCustomerID}
        setSelectedCustomerID={setSelectedCustomerID}
        setNewCustomerPopupIsActive={setNewCustomerPopupIsActive}
        fetchActivities={fetchActivities}
        myBrands={myBrands}
        selectedActivity={selectedActivity}
        editActivity={editActivity}
        setEditActivity={setEditActivity}
        createReport={creatReport}
        setCreateReport={setCreateReport}
        setLoading={setLoading}
        myTeam={myTeam}
      />

      <PopupwindowFiltersMobile
        id={"popup-window-filters--activities-page"}
        isActive={filtersPopupIsActive}
        setIsActive={setFiltersPopupIsActive}
        filters={filters}
        updateFilters={updateFilters}
        refreshState={resetActivitiesState}
        myTeamArray={myTeam}
      />

      <div className='template__body--mobile' id='mobile-activities-page'>
        <div className='body__section--transparent' id='header--mobile'>
          <h1>Activities</h1>
          <div className='header__buttons'>
            <div className='header__button' onClick={() => fetchActivities(true)}><VscRefresh /></div>
            <div className='header__button' onClick={() => setFiltersPopupIsActive(!filtersPopupIsActive)}><CiFilter /></div>
            <div className='header__button' onClick={() => setCreateActivityPopupIsActive(true)}><PiPlusThin /></div>
          </div>
        </div>
        <div className='body__section--transparent' id='activities-table'>
          {
            loading ?
              <div className='circular_loading_spinner__wrapper'><CircularProgress color='inherit' size="2rem" /></div>
              :
              <div className='table no-scrollbar'>
                {
                  activities.map((activity, index) => {

                    let isOverdue = false
                    if (new Date(activity.planned_date) < now & !activity.done) {
                      isOverdue = true
                    }

                    let owner = ""
                    if (activity.sales_rep_id.last_name) {
                      owner = `${activity.sales_rep_id?.first_name} ${activity.sales_rep_id.last_name.charAt(0)}.`
                    } else {
                      owner = `${activity.sales_rep_id?.first_name}`
                    }

                    return (
                      <div className='table__row_wrapper' key={index} >
                        <div className={`activity_container--mobile ${isOverdue ? "overdue" : ""} ${activity.done ? "done" : ""}`}>
                          <div className='activity_data_field check-box'>
                            <CheckBox
                              index={activity.id}
                              state={activity.done}
                              width={"30px"}
                              height={"30px"}
                              margin={"0"}
                              setData={handlePreCheckDone}
                              style={"mobile"}
                              type={"controlled"}
                            />
                          </div>
                          <div className='activity_data_field title'>{activity.title}</div>
                          <div className='activity_data_field customer'>{activity.customer_id.business_name}</div>
                          {/* <div className='activity_data_field picto'>{activity_types_svgs[activity.type].picto}</div> */}
                          <div className='activity_data_field owner'>{owner}</div>
                          <div className='activity_data_field date'>{formatDateWithTime(activity.planned_date, true)}</div>
                        </div>
                        <div className='table__row--field--arrow' onClick={(e) => handleEdit(e, activity.id)}><PiCaretRightThin /></div>
                      </div>
                    )
                  })
                }
                <div className="scroll_for_more__element--mobile" ref={scrollForMoreRef}>
                  {
                    hasMore ?
                      <>
                        {
                          loadingList ?
                            <CircularProgress color="inherit" size="2rem" /> :
                            <div className='scroll_for_more__text'><PiCaretDoubleDownThin /> Scroll for more <PiCaretDoubleDownThin /></div>
                        }
                      </> : null
                  }
                </div>
              </div>
          }


        </div>

      </div>

      <MenuBarMobile />
    </div>
  )
}

export default ActivitiesPageMobile