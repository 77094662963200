import React, { useState, useEffect } from "react"
import httpClient from "../httpClient"
import "./SignupPage.css"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import CircularProgress from '@mui/material/CircularProgress'

import { useSelector, useDispatch } from "react-redux"
import { addUserInfo } from "../store/user"
import { changeNotificationMessage } from "../store/notification"
import { NotificationTop, showNotification } from "../components/NotificationTop"

function SignupPage() {

  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [error, setError] = useState("")

  const logo_black = require("../assets/logos/Stoqup_logo_black.png")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {

  }, [])

  const signupUser = async () => {
    setLoading(true)

    const type = "crm_user"

    if (!companyName | !email | !password) {
      toast.error('Please complete all fields', { theme: 'dark' })
    }

    try {
      const data = { email, password, type, companyName }
      const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/register", data)

      if (response.status === 200) {
        navigate(`/email-verification/${response.data.id}`)
      }

      if (response.status === 409) {
        const message = "User with this email address already exists"
        const errorType = "red"
        dispatch(changeNotificationMessage(message))
        showNotification(errorType)
      }

    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  return (
    <div className="body-loginpage">
      <NotificationTop />
      <Link className="logo" to="/"><img src={logo_black} alt="stoqup logo black" /></Link>
      <form className='login-form'>
        <input
          value={companyName}
          onChange={e => setCompanyName(e.target.value)}
          placeholder='COMPANY NAME'
        />
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder='EMAIL'
        />
        <input
          value={password}
          onChange={e => setPassword(e.target.value)}
          type='password'
          placeholder='PASSWORD'
        />

        <button type='button' className='button-login' onClick={signupUser}>
          <div className={`${loading ? "hide" : ""}`}>SIGN UP</div>
          {
            loading &&
            <CircularProgress color="inherit" size={"2.3rem"} />
          }
        </button>
      </form>
    </div>
  )
}

export default SignupPage
