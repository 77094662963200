import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './AlertPopupMobile.css'
import { MdErrorOutline } from "react-icons/md"
import { IoNotificationsOutline } from "react-icons/io5"
import { CiCircleCheck } from "react-icons/ci"
import { hideAlertPopup } from '../../store/notificationAlert'

function AlertPopupMobile() {
  const dispatch = useDispatch()
  const { isActive, type, message } = useSelector(state => state.notificationAlert)

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        dispatch(hideAlertPopup())
      }, 5000);
    }
  }, [isActive, dispatch])

  const renderIcon = () => {
    switch (type) {
      case "error":
        return <MdErrorOutline/>
      case "notification":
        return <IoNotificationsOutline/>
      case "success":
        return <CiCircleCheck/>
    }
  }

  return (
    <div className={`alert--mobile__wrapper ${isActive ? "active" : ""} ${type}`} onClick={() => dispatch(hideAlertPopup())}>
      <div className='alert--mobile'>
        <div className='alert__close'>x</div>
        <div className='alert__icon'>{renderIcon()}</div>
        <div className='alert__message'>{message}</div>
      </div>
    </div>
  )
}

export default AlertPopupMobile

AlertPopupMobile.defaultProps = {
  type: "notification",
  message: "",
}
