import React, { useState, useEffect } from 'react'
import httpClient from '../httpClient'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './CustomersToContact.css'
import CircularProgress from '@mui/material/CircularProgress'

function CustomersToContact({ userType }) {
  const [customersToContact, setCustomersToContact] = useState([])
  const [contactFilter, setContactFilter] = useState("expired")
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  const isMobile = useSelector(state => state.global.isMobile)
  const noMoreCustomersPicto = require(`../assets/pictos/folder.png`)

  const fetchCustomersToContact = async () => {
    setLoading(true)
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/customers/to-contact?filter=${contactFilter}`)
    setCustomersToContact(response.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchCustomersToContact()
  }, [contactFilter])

  const setFilter = (event, value) => {
    setContactFilter(value)

    const filters = document.getElementsByClassName("contact-filter")
    Array.from(filters).forEach(filter => filter.classList.remove("underlined"))

    event.target.classList.add("underlined")
  }

  const onClickCustomer = (event, customerId) => {
    event.stopPropagation()

    const link = `/customer/${customerId}`
    navigate(link)
  }

  return (
    <div className={`body__section_content__wrapper ${isMobile ? "mobile" : ""}`} id='customers-to-contact-wrapper'>
      <div className="flex">
        <h2>customers to contact</h2>
      </div>
      <div className="filters-wrapper">
        <p className="contact-filter underlined" onClick={(event) => setFilter(event, "expired")}>expired</p>
        <p className="contact-filter" onClick={(event) => setFilter(event, "to_be_expired")}>to be expired</p>
      </div>
      <div className="customers-to-contact__list">
        {
          loading ?
            <div className='circular_loading_spinner__wrapper'><CircularProgress color='inherit' size="2rem" /></div>
            :
            customersToContact.length === 0 ?
              <div className="all_done__container">
                <img src={noMoreCustomersPicto} />
                <p>No more customers to contact</p>
              </div>
              :
              <ul>
                {
                  customersToContact.map((customer, index) => {

                    let owner = "No owner"
                    if (customer.owner?.last_name) {
                      owner = `${customer.owner?.first_name} ${customer.owner?.last_name.charAt(0)}.`
                    } else if (customer.owner?.first_name) {
                      owner = `${customer.owner?.first_name}`
                    } else {
                      owner = "No owner"
                    }

                    return (
                      <li
                        key={index}
                        className={`customer-item ${contactFilter === "expired" ? "expired" : "to_be_expired"} ${userType? "grid-3" : ""}`}
                        onClick={(e) => onClickCustomer(e, customer.customer_id.user_id.id)}
                      >
                        <span className="customer-name">{customer.customer_id.business_name}</span>
                        <span className="days-left">
                          {customer.days_to_next_contact <= 0
                            ? `${Math.abs(customer.days_to_next_contact)} days over`
                            : `${customer.days_to_next_contact} days left`}
                        </span>
                        {
                          userType === "manager" && (
                            <span className="assigned-to">{owner}</span>
                          )
                        }
                      </li>
                    )
                  })    
                }
              </ul>
        }
      </div>
    </div>
  )
}

export default CustomersToContact
