import React, { useState, useEffect } from 'react'
import './PopOutWindow.css'
import CloseIcon from '@mui/icons-material/Close'

export function PopOutWindow({ popupID, onClose, children, maxHeight, isActive, setIsActive, width }) {

  useEffect(() => {
    const popup = document.getElementById(popupID)
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })
  }, [])

  const closePopupWindow = () => {
    const popup = document.getElementById(popupID)
    popup.classList.remove("active")
    setIsActive(false)
    onClose()
  }

  return (
    <div
      className={`popout__container ${isActive ? "active" : ""}`}
      id={popupID}
      onClick={closePopupWindow}
    >
      <div
        className='popout__box'
        style={{ maxHeight, width }}
        id={popupID}
        onClick={e => e.stopPropagation()}
      >
        <CloseIcon id="popup__close-icon" onClick={closePopupWindow} />
        {children}
      </div>
    </div>
  )
}

PopOutWindow.defaultProps = {
  onClose: () => console.log(""),
  overflow: "scroll",
  maxHeight: "75%",
  width: "50%"
}