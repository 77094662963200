import React, { useState, useEffect, useRef } from 'react'
import httpClient from '../../httpClient'
import { useSelector, useDispatch } from 'react-redux'
import './PopupwindowSideMenu.css'
import { Link } from 'react-router-dom'
import { FaAddressCard } from "react-icons/fa6"
import { FaRectangleList } from "react-icons/fa6"
import { FaCircleCheck } from "react-icons/fa6"
import { FaTruck } from "react-icons/fa6"
import { FaCalendarAlt } from "react-icons/fa"
import { FaImage } from "react-icons/fa6"

function PopupwindowSideMenu({ id, isActive, setIsActive, selectedMenu, setSelectedMenu }) {


  const dispatch = useDispatch()
  const thisPopupContainerRef = useRef(null)

  const handleOnclickTitle = (e) => {
    const menuTitles = document.querySelectorAll(".menu_title")
    const menuName = e.currentTarget.getAttribute("data-menu-name")

    menuTitles.forEach(title => title.classList.remove("selected"))

    e.currentTarget.classList.add("selected")
    setSelectedMenu(menuName)
    setIsActive(false)
  }

  return (
    <div className={`popup_window__container menu ${isActive ? "active" : ""}`} id={id} ref={thisPopupContainerRef}>

      <div className='popup_header'>
      </div>
      <div className='popup_body'>
        <div className='side_menu__titles'>
          <div className='menu_title selected' data-menu-name="CONTACT" onClick={handleOnclickTitle}><FaAddressCard /> Contact Details</div>
          <div className='menu_title' data-menu-name="DETAILS" onClick={handleOnclickTitle}><FaRectangleList /> Details</div>
          <div className='menu_title' data-menu-name="AVAILABILITY" onClick={handleOnclickTitle}><FaCircleCheck /> Availability</div>
          <div className='menu_title' data-menu-name="SUPPLIERS" onClick={handleOnclickTitle}><FaTruck /> Suppliers</div>
          <div className='menu_title' data-menu-name="CONTENT" onClick={handleOnclickTitle}><FaImage /> Content</div>
          <div className='menu_title' data-menu-name="ACTIVITIES" onClick={handleOnclickTitle}><FaCalendarAlt /> Activities</div>
        </div>
      </div>
    </div>
  )
}

export default PopupwindowSideMenu

PopupwindowSideMenu.defaultProps = {
  id: "popup-window-mobile,"
}

